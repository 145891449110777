import React from "react";
import { header, questions, lastBanner } from "../../utils/how-it-works/questionsInformations.js";
import Banner from "../../components/banner/banner.js";
import ReviewCards from "../../components/review-cards/review-cards.js";
import Layout from "../../layouts/index.js";
import "../../styles/pages/questions.scss";


const Questions = () => {


    return (
        <Layout SEOTitle={"Questions & Answers | Free Motor Legal"} SEODescription={""} footerDisclaimer={""}>

            <Banner props={header} />
            <div className="padding">
                <ReviewCards props={questions} />
            </div>
            <Banner props={lastBanner} />
        </Layout>

    )
}

export default Questions;