import  {FaAnglesDown} from "react-icons/fa";
import "../../styles/variables.scss";


export const header = {
    small: false,
    title: "Got questions?",
    h1: true,
    bgColor: "#0bbcbf",
    displayBtn: false,
    padding: true,
}

export const lastBanner = {
    small: false,
    displayBtn: true,
    title: "Ready to sign up?",
    bgColor: '#a8cf37',
    label: "SIGN UP NOW",
    link: "true",
    url: "/sign-up-to-fml/",
}

export const questions = {
    title: '',
    description: '',
    cardsPerLine: 2,
    reviewList: [
      {
        author: "How can all of this be free?",
        review: `Like usual motor legal protection insurance, we earn some commissions from our business partners when their services are used. For example, if we arrange a replacement hire car for you, our business partner will pay us a referral fee. The same if we arrange for you to have medical treatment following injury. Whilst this is the same practice that 99% of insurers & brokers do, we do not charge you £30 each year for a motor legal protection policy on top. This genuinely is free legal help.`
      },
      {
        author: "Can anyone join?",
        review: `Yes – so long as you are over 18 and a resident in England, Scotland or Wales you can join. Unfortunately we do not cover Northern Ireland, the Republic of Ireland, channel islands or mainland Europe. Registration is open to both private vehicle owners (both car & motorcycle) as well as commercial and fleet operators, such as taxis, driving instructors, HGV, bus and coach operators. Whether you have one vehicle or a fleet of 1000+, we are here for you.`
      },
      {
        author: "Does it only assist me?",
        review: `No. If you sign up, you and any family members get the same benefits. It even extends to any other passengers in your vehicle at the time of the accident. Not only that, but you also are protected if you are a passenger in another vehicle or when using public transport and suffer injury or financial loss. This is something that legal expenses policies do not always cover.`
      },
      {
        author: "Do I still need to buy car insurance?",
        review: `Yes. Every motorist needs insurance – either third party or comprehensive. What you DON’T need to pay for is Motor Legal Protection Insurance, sometimes called Legal Expenses Insurance. You will find it listed as an optional extra or add on. If you sign up to Free Motor Legal instead, you get the same benefits – and more – without paying anything.`
      },
      {
        author: "What happens when I sign up?",
        review: `It takes seconds. Just complete the form on the Sign Up page. You will receive an email with membership pack that includes vital contact details should you be involved in a motor accident that wasn’t your fault. Your print this out and keep this pack in your glove box.That’s it. You don’t have to do anything else until you have an accident. You don’t need to renew it each year.`
      },
      {
        author: "I've had an accident. What should I do?",
        review: `Call Free Motor Legal first on 0800 144 4488. This is a 24/7 freephone number. We will help you collect the right information from the scene of the accident, arrange for roadside assistance and get you home. And we’ll tell you what to do next. Call us before calling your insurance company to avoid paying an excess or affecting your no claims discount. Call us even if you aren’t sure if the accident was your fault. We’ll advise you on what to do next. You do have a duty to inform your own insurer about the incident, but you can report the incident for information purposes only, meaning you tell them about the collision, but inform them you are not wishing to pursue a claim via your own policy. This would save you having to pay any excess.`
      },
      {
        author: "I've lost my accident survival checklist. Can I get another one?",
        review: `Absolutely. Click here to download a pdf version. Print it and keep a copy in the glovebox of every vehicle you own.`
      },
      {
        author: "Mr P. Mason",
        review: `I had paid for legal cover, after 1 year of no help I signed up to FreeMotor Legal, where they helped and sorted the probloem out in a matter of weeks. Without FreeMotorLegal I would not have been able to claim on the other party’s insurance. I personally recommend from experience, no fees, quality help & advice right till the end.`
      },
      {
        author: "Is this legal expenses insurance?",
        review: `No – there is no insurance involved. This is a free membership organisation that gives you similar benefits as legal insurance without charging you for it. But it is not an insurance policy. We are fully regulated by the Claims Management Regulator, overseen by the Ministry of Justice. Any complaints can be escalated to the legal ombudsman.`
      },
      {
        author: "Is this just a way to sell my details onto other companies?",
        review: `Absolutely not. Read our privacy policy. We hate unsolicited marketing calls as much as you do. So we guarantee that we will never sell your details on to unconnected third party companies. You join to get the best free legal help following a non-fault motor claim. And that’s it.`
      },
      {
        author: "Why not just use one of those no-win, no-fee lawyers who advertise on TV?",
        review: `Because they are only interested in acting for you in relation to personal injury claims.

        They will not get your vehicle repaired or arrange for you to have medical treatment. They will not sort a replacement car, bike or truck. Also, when you have had an accident, do you really have time to go searching around for somebody to deal with your claim?
        
        By being a member of Free Motor Legal all you have to do is call the number on your key ring or membership card and we will take care of the rest. `
      },
      {
        author: "Do your services apply if I have an accident outside of England, Wales or Scotland?",
        review: `Our services only cover you if you have an accident in mainland UK – England, Scotland and Wales. Due to complexities with different legal systems, medical cover and with the challenge of returning vehicles from abroad, we cannot provide our services outside of these areas. If you regularly travel outside of the mainland UK, give us a call to find out what your options are. We’ll give you advice for free to ensure you are adequately covered.`
      },
      
    ]
  }